import '../styles/main.scss'

if (module.hot) module.hot.accept()

class Swipes {
  constructor() {
    this.currentTouch = null
    this.swipes = [...document.getElementsByClassName('story__swipe')]
    this.radios = [...document.getElementsByClassName('story__radio')]
    this.currentStepDisplay = document.getElementById('currentStepDisplay')
    this.next = document.getElementById('storyNext')
    this.images = [...document.getElementsByClassName('story__img')]
    this.previous = document.getElementById('storyPrev')
    this.storyMainContainer = document.getElementById('storyMainContainer')
    this.currentIndex = this.radios.findIndex((radio) => radio.checked)

    if (window.location.search.split('=')[1]) {
      if (window.location.search.split('=')[1] < this.radios.length) {
        this.currentIndex = parseInt(window.location.search.split('=')[1], 10)
        this.onClickArrow(0)
      }
    }

    this.setStoryMainContainerHeight()
    this.bindEvents()
  }

  bindEvents = () => {
    this.previous.addEventListener('click', () => this.onClickArrow(-1))
    this.next.addEventListener('click', () => this.onClickArrow(1))
    this.images.forEach((image) => {
      image.addEventListener('mousedown', () => this.onClickArrow(1))
    })
    this.swipes.forEach((swipe) => {
      swipe.addEventListener('touchstart', (e) => this.onTouchStart(e))
      swipe.addEventListener('touchmove', (e) => this.onTouchMove(e))
      swipe.addEventListener('touchend', (e) => this.onTouchEnd(e))
    })
    document.addEventListener('keydown', this.onKeyDown)
  }

  onTouchStart = (e) => {
    this.currentTouch = { x: e.touches[0].clientX, y: e.touches[0].clientY }
  }

  onTouchMove = (e) => {
    const currentTouch = { x: e.touches[0].clientX, y: e.touches[0].clientY }
    const storyDescription = document.querySelector('.story__description')

    if (this.currentTouch) {
      if (!e.path.includes(storyDescription)) {
        if (currentTouch.x - this.currentTouch.x <= 0) {
          this.onClickArrow(1)
        } else {
          this.onClickArrow(-1)
        }
        this.currentTouch = null
      }
    }

    this.swipes.forEach((swipe) => {
      swipe.removeEventListener('touchmove', (ev) => this.onTouchMove(ev))
    })
  }

  onTouchEnd = () => {
    this.currentTouch = null
  }

  onKeyDown = (e) => {
    // 39 == right arrow key
    // 37 == left arrow key
    // 27 == esc key
    switch (e.which) {
      case 39:
        this.onClickArrow(1)
        break
      case 37:
        this.onClickArrow(-1)
        break
      default:
        break
    }
  }

  onWindowResize = () => {
    this.setStoryMainContainerHeight()
  }

  setStoryMainContainerHeight = () => {
    const headerHeight = document.getElementsByClassName('header')[0].getBoundingClientRect().height
    const footerHeight = document.getElementsByClassName('footer')[0].getBoundingClientRect().height
    const controlsHeight = document
      .getElementsByClassName('story__controls')[0]
      .getBoundingClientRect().height

    this.storyMainContainer.style.minHeight = `${
      window.innerHeight - headerHeight - footerHeight
    }px`

    this.images.forEach((image) => {
      /* eslint-disable */
      image.style.maxHeight = `${
        window.innerHeight - headerHeight - footerHeight - controlsHeight
      }px`
      /* eslint-enable */
    })
  }

  onClickArrow = (step) => {
    this.radios.forEach((radio) => {
      /* eslint-disable */
      radio.checked = false
      /* eslint-enable */
    })

    let nextStep = this.currentIndex + step

    if (nextStep < 0) {
      nextStep = this.radios.length - 1
    } else if (nextStep >= this.radios.length) {
      nextStep = 0
    }

    this.radios[nextStep].checked = true

    this.currentStepDisplay.innerText = nextStep + 1

    this.currentIndex = nextStep

    if (step) {
      window.history.pushState('', '', `${window.location.pathname}?step=${this.currentIndex}`)
    }
  }
}

document.addEventListener('DOMContentLoaded', function DOMContentLoaded() {
  if (document.body.classList.contains('single-story')) {
    /* eslint-disable */
    new Swipes()
    /* eslint-enable */
  }
})
